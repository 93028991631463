import React, { FC, useEffect, useState } from "react";
import { NavMenuPanel } from "@components/sections/header/menu/nav-menu-panel";
import { LogoLink } from "@components/links/logo-link";
import { Menu } from "@data/menu";
import { useRouter } from "next/router";

const NavMenuWithoutBanner: FC = ({}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [scrolledMenu, setScrolledMenu] = useState(false);
  const [isSubMenuShown, setIsSubMenuShown] = useState(``);
  const onScroll = () => {
    // Get the current scroll position
    const currentScrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    setScrolledMenu(currentScrollPosition >= 75);
  };
  useEffect(() => {
    onScroll();
    window.addEventListener("scroll", onScroll);
  });
  const router = useRouter();
  const routeArray = router.route.split("/");
  const route =
    routeArray.length > 2 ? routeArray[routeArray.length - 1] : routeArray[1];
  //setScrolledMenu
  return (
    <nav
      id="navbar"
      className={`z-[333] flex flex-row relative w-full items-baseline md:py-0 lg:px-8 md:flex justify-end md:justify-between md:items-start
             ${
               showMenu
                 ? "fixed bg-crowlyn-dark md:bg-transparent h-screen top-0 bottom-0 left-0 right-0 overflow-y-scroll"
                 : scrolledMenu
                 ? "bg-crowlyn-dark md:bg-opacity-100 fixed top-0 md:bg-white "
                 : "bg-crowlyn-dark"
             }`}
    >
      <div className="lg:container mx-auto w-full md:pb-4 md:border-b-[1px] md:border-b-gray-400">
        <div className="h-full md:h-auto md:flex md:flex-row md:w-full md:justify-between md:mx-0">
          <LogoLink />
          <NavMenuPanel
            scrolledMenu={scrolledMenu}
            menu={Menu}
            showMenu={showMenu}
            isSubMenuShown={isSubMenuShown}
            setIsSubMenuShown={setIsSubMenuShown}
            setShowMenu={setShowMenu}
            route={route}
          />
        </div>
      </div>
    </nav>
  );
};

export default NavMenuWithoutBanner;
